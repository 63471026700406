<template>
  <div class="footer">
    <div class="top col alc colfff">
      <p class="top-tit">数字软件技术、数字媒体传播解决方案提供商</p>
      <form action="" class="form">
        <div class="input-list row alc">
          <label for="name">
            <span class="colfff">称呼</span>
            <input
              type="text"
              class="input"
              id="name"
              autocomplete="off"
              v-model="ciName"
            />
          </label>
        </div>
        <div class="input-list row alc">
          <label for="phone">
            <span class="colfff">联系电话</span>
            <input
              type="text"
              class="input"
              id="phone"
              autocomplete="off"
              v-model="ciPhone"
            />
          </label>
        </div>
        <div class="input-list row alc">
          <label for="nickname">
            <span class="colfff">企业名称</span>
            <input
              type="text"
              class="input"
              id="nickname"
              autocomplete="off"
              v-model="ciCompanyName"
            />
          </label>
        </div>
      </form>
      <div class="button" @click="contac">
        <img class="img" src="../assets/image/组 39.png" />
        <div
          style="
            margin: 0 10px 0 0px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0em;
            color: #ffffff;
          "
        >
          联系我们
        </div>
      </div>
      <img class="top-bg" src="../assets/image/img.png" />
      <img class="top-bg1" src="../assets/image/img 1.png" />
      <p class="copy" style="margin-top: 20px; margin-bottom: 10px">
        Copyright©2021 中鸿云智 Rights Reserved. 中鸿云智（浙江）科技有限公司
        <a href="http://beian.miit.gov.cn/" target="_blank"
          >浙ICP备2023002482号-1</a
        >
      </p>
      <div style="width: 300px; margin: 0 auto;">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502007339"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
        ><img src="../assets/image/icon.png" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            /* color: #939393; */
          "
        >
          浙公网安备 33010502007339号
        </p></a
      >
    </div>
    </div>
  </div>
</template>
<script>
import { contacInfo } from "../utils/api";
export default {
  data() {
    return {
      ciCompanyName: "",
      ciPhone: "",
      ciName: "",
    };
  },
  props: {},
  components: {},
  methods: {
    gotoPath(path) {
      this.$router.push(path);
    },
    contac() {
      if (this.ciName == "") {
        this.$message("请输入您的称呼");
        return;
      }

      if (this.ciPhone == "") {
        this.$message("请输入您的联系电话");
        return;
      }

      if (this.ciCompanyName == "") {
        this.$message("请输入您的企业名称");
        return;
      }
      let parmas = {
        ciCompanyName: this.ciCompanyName,
        ciPhone: this.ciPhone,
        ciName: this.ciName,
        ciUaId: 1,
      };
      contacInfo(parmas).then((res) => {
        if (res.code == 20000) {
          this.ciCompanyName = "";
          this.ciPhone = "";
          this.ciName = "";
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  //   position: fixed;
  //   bottom: 0;
  width: 100%;
  font-size: 18px;
  .top {
    position: relative;
    height: 480px;
    // background: #0068ff;

    background: linear-gradient(#eef2fb, #fff);
    .top-tit {
      padding-top: 34px;
      font-size: 36px;
      line-height: 67px;
      z-index: 1;
      margin-bottom: 28px;
    }
    .input-list {
      width: 600px;
      border-bottom: 1px solid #333333;
      padding: 20px 0 10px;
    }
    .form {
      z-index: 1;
      font-size: 24px;
      .input {
        line-height: 40px;
        background: transparent;
        border: none;
        outline: 0;
        color: #333 !important;
        font-size: 24px;
        padding: 0 20px;
        margin-left: 20px;
      }

      input:-internal-autofill-previewed {
        -webkit-text-fill-color: #ffffff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
      input:-internal-autofill-selected {
        -webkit-text-fill-color: #ffffff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
    }
    .top-bg {
      position: absolute;
      top: 50%;
      left: 7%;
      transform: translate(0, -50%);
      // width: 100%;
      height: 50%;
      z-index: 0;
    }
    .top-bg1 {
      position: absolute;
      top: 50%;
      right: 7%;
      transform: translate(0, -50%);
      // width: 100%;
      height: 50%;
      z-index: 0;
    }
    .button {
      width: 203px;
      height: 50px;
      background: linear-gradient(270deg, #1d8bff 2%, #61fff9 100%);
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      margin-top: 48px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 10px;
      }
    }
  }
}
</style>
