<template>
  <div class="container">
    <div class="right"
         style="width: 100%">
      <!-- 公司简介 -->

      <div class="bgImg">
        <!-- <div class="bgImg_title3">数字软件技术，数字媒体传播解决方案提供商</div> -->
        <div class="introduce">{{uaCompanySlogan}}</div>
      </div>
      <div class="input_bg2">
        <div class="input_bg"
             style="padding: 2px; box-sizing: border-box; font-size: 16px">
          <i class="el-icon-search"
             @click="search"></i>
          <input style="height: 30px; font-size: 16px"
                 type="text"
                 placeholder="请输入您想要的内容"
                 @input="print($event.target.value)" />
        </div>
      </div>
      <div class="newItemList">
        <div class="new_item"
             v-for="(item, index) in data"
             :key="index"
             @click="ToNewDetail(item.nid)">
          <div class="new_item_left">
            <img :src="item.npictureUrl"
                 alt="" />
          </div>
          <div class="new_item_right">
            <div class="new_item_right_title">
              <div class="new_item_right_title_left">
                {{ item.ntitle }}
              </div>

            </div>
            <div class="new_item_right_title_right">{{ item.ncreateTime }}</div>
            <!-- <div class="new_item_right_content" v-html="item.ncontent"></div> -->
          </div>
        </div>
      </div>
      <!-- <div style="  padding-bottom: 40px;color: #969799; font-size:12px;margin-top:20px">丹道提供技术支持</div> -->
      <Fotter></Fotter>
    </div>
  </div>
</template>

<script>
import { getNewList, getInfo } from "../../utils/api.js";
import Fotter from "../../components/fotter.vue";
export default {
  components:{
    Fotter,
  },
  data () {

    return {
      data: [
        // {
        //   date: "2021-08-08",
        //   picture:
        //     "https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1797441126432.png",
        //   title: "市委常委考察丹道业务",
        //   content:
        //     "市委常委、宣传部部长戚哮虎，民盟杭州市委会主委、市统计局局长宦金元一行莅临查看我司数字协商平台的工作机制和实体演示。对我企的关怀",
        // },

      ],
      title: "",
      fullscreen: false,
      uaCompanySlogan: ''
    };
  },
  created () {
    this.getNewList();
    this.getInfo()
  },
  methods: {
    search () {
      console.log(this.title, 1111);
      this.getNewList();
    },
    print (e) {
      console.log(e);
      this.title = e;
    },
    getInfo () {
      getInfo({ uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res.data);
          this.uaCompanySlogan = res.data.uaCompanySlogan
        }
      })
    },
    getNewList () {
      getNewList({ pageSize: 300, pageNum: 1, title: this.title, uaId: 1 }).then(
        (res) => {
          if (res.code == 20000) {
            console.log(res);
            for (var i = 0; i < res.data.dataList.length; i++) {
              if (res.data.dataList[i].ncontent) {
                res.data.dataList[i].ncontent = res.data.dataList[
                  i
                ].ncontent.replace(/<\/?(img)[^>]*>/gi, "");
              }
            }
            this.data = res.data.dataList;
          }
        }
      );
    },
    ToNewDetail (id) {
      console.log(id);
      this.$router.push({ path: "/newDetails", query: { id: id } });
    },
  },
};
</script>

<style scoped >
.container {
  background: #fff;
  width: 100%;
  height: 100vh;
}

.right {
  width: 100%;
  /* height: 100vh; */
  background: #fefefe;
  position: absolute;
  /* z-index: 100; */
  top: 0;
  right: 0;
}
.bgImg {
  width: 100%;
  height: 280px;
  /* margin-top:40px; */
  background: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/git.gif") no-repeat;
  background-size: 100% 250%;
  position: relative;
  margin-top: 5%;
}
.bgImg_title3 {
  font-size: 28px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  color: #fff;
  text-align: left;
}
.introduce {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 0.27rem;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.input_bg2 {
  height: 8vh;
  /* padding: 1.5vh;
  box-sizing: border-box; */
  margin-top: 20px;
}
.input_bg {
  border-radius: 20px;
  background: #f3f4f5;
  width: 350px;
  margin-left: 6%;
  /* height: 5vh; */
  line-height: 5vh;
}
.input_bg input {
  font-size: 16px;
  border: none;
  background: #f3f4f5;
  width: 320px;
  height: 35px;
  border-radius: 20px;
  padding-left: 10px;
}
.newItemList {
  width: 100%;
  /* height: 72vh; */
  /* overflow: scroll; */
  /* padding-bottom: 50px; */
}
.new_item {
  width: 86%;
  height: 120px;
  padding: 8px 10px;
  box-sizing: border-box;
  margin: 15px auto;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
}
.new_item img {
  width: 140px;
  height: 100px;
  margin-right: 10px;
}
.new_item_right_title {
  width: 100%;

  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new_item_right {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
}
.new_item_right_title_left {
  width: 100%;
  font-size: 16px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.new_item_right_title_right {
  width: 100%;
  text-align: right;

  font-size: 10px;
  color: #a2a2a2;
}
.new_item_right_title :nth-child(1) {
}
.new_item_right_content {
  width: 100%;
  color: #a2a2a2;
  font-size: 15px;
  text-align: left;
  margin-top: 10px;
  color: #a2a2a2;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>